import React from 'react'
import { Layout } from 'antd'

const TermsPage = () => (
    <Layout>
        <div style={{ alignSelf: 'center', maxWidth: '600px', padding: '10px' }}>
            <h1>Persondata- og cookiepolitik</h1>
            <p>
                Når du besøger Ikenson ApS websites eller på anden måde er i kontakt med Ikenson
                ApS, kan der ske indsamling af persondata.Vi indsamler ikke personoplysninger, uden
                at du selv har givet os disse oplysninger. Det kan ske ved bestilling af
                abonnementer, når du handler med os i andre sammenhænge, eller ved brug af vores
                services. Her indsamles typisk oplysninger om navn, adresse, telefon nummer og
                e-mail. Se mere specifikt i de konkrete afsnit nedenfor.
            </p>

            <h3>Beskyttelse af personoplysninger</h3>
            <p>
                Ifølge databeskyttelsesloven og EU’s Persondataforordning (General Data Protection
                Regulation, GDPR) skal dine personlige oplysninger opbevares sikkert og fortroligt.
                Vi gemmer dine personlige oplysninger på servere med begrænset adgang, som er
                placeret i kontrollerede faciliteter, og vores sikkerhedsforanstaltninger
                kontrolleres løbende for at afgøre, om vores brugeroplysninger håndteres forsvarligt
                og under stadig hensyntagen til dine rettigheder som bruger.
            </p>

            <p>
                Vi kan dog ikke garantere 100 procent sikkerhed ved dataoverførsler via internettet.
                Det betyder, at der kan være en risiko for, at andre uberettiget tiltvinger sig
                adgang til oplysninger, når data sendes og opbevares elektronisk.
            </p>

            <p>
                Den hurtige udvikling af internettet betyder, at ændringer i vores behandling af
                personoplysninger kan blive nødvendige. Vi forbeholder os derfor ret til at opdatere
                og ændre nærværende retningslinjer for behandling af personoplysninger.
            </p>

            <p>
                Gør vi det, retter vi selvfølgelig datoen for "senest opdateret" nederst i
                dokumentet. I tilfælde af væsentlige ændringer giver vi dig besked i form af en
                synlig meddelelse på vores websites.
            </p>

            <h3>Dine rettigheder og kontakt vedr. personoplysninger</h3>

            <p>
                Du har ret til at få indsigt i de oplysninger, som vi behandler om dig. Hvis du
                ønsker adgang til de oplysninger, som er registreret om dig hos Ikenson ApS, skal du
                rette henvendelse til info@ll33a.dk.
            </p>

            <p>
                Er der registreret forkerte data, eller har du andre indsigelser, kan du rette
                henvendelse samme sted.
            </p>

            <p>
                Du har mulighed for at få indsigt i, hvilke informationer der er registreret om dig,
                og du kan gøre indsigelse mod en registrering i henhold til reglerne i
                databeskyttelsesloven og EU’s Persondataforordning.
            </p>

            <p>
                I det omfang, der behandles personoplysninger om dig, har du ifølge persondataloven
                og Persondataforordningen ret til at få oplyst, hvilke personoplysninger der kan
                henføres til dig.
            </p>

            <p>
                Såfremt det viser sig, at de oplysninger eller data, der behandles om dig, er
                urigtige eller vildledende, har du ret til at kræve disse berigtiget, slettet eller
                blokeret. Du kan til enhver tid gøre indsigelse mod, at oplysninger om dig gøres til
                genstand for behandling. Du kan også til enhver tid tilbagekalde dit samtykke.
            </p>

            <p>
                Du har endvidere ret til at blive slettet fra Ikenson ApS’s databaser (Right to be
                erased) og ret til, at al historik om din adfærd slettes (Right to be forgotten).
            </p>

            <p>
                Du har mulighed for at klage over behandlingen af oplysninger og data vedrørende
                dig. Klage indgives til Datatilsynet.
            </p>

            <p>
                Du kan i det følgende læse vores persondatapolitik på en række specifikke områder.
            </p>

            <h3>Når du abonnerer på vores nyhedsbreve:</h3>

            <p>
                Formålet med databehandlingen er at sende dig de nyhedsbreve, som du abonnerer på,
                samt at levere indhold målrettet til dig.
            </p>

            <p>
                Retsgrundlaget for databehandlingen er dit samtykke ved tilmelding til de ønskede
                nyhedsmails. Du kan til enhver tid afmelde dig nyhedsbrevene.
            </p>

            <h3>Når du bliver bruger hos os, behandles følgende personoplysninger:</h3>

            <p>
                Formål: Når du opretter dig som bruger, har vi en række forpligtelser over for dig
                som bruger. Disse forpligtelser indebærer følgende:
                <br />
                - Når vi modtager elektroniske dokumenter fra dig, opbevarer vi dem på servere, pt i
                Frankfurt, Tyskland - vi samarbejder i den forbindelse med en række leverandører.
                <br />
                - På baggrund af dine indtastede oplysninger om dage med ophold i Danmark, viser vi
                dig grafisk din relation til “42-dages-reglen”.
                <br />
                - Med baggrund i dine data kompilere vi rapporter, med det formål, at dokumentere
                din overholdelse af “42-dages-reglen” i henhold til LL§33A.
                <br />
                Hvilke typer personoplysninger behandler vi:
                <br />
                - Vi opbevarer adresseoplysninger, IP-adresse, E-mail, Navn, og Telefon nr.
                <br />
                Retsgrundlaget for vores behandling af dine personoplysninger følger af:
                <br />
                - Vi registrerer oplysningerne med dit samtykke i forbindelse med din oprettelse af
                bruger profil hos os.
                <br />
                Hvor stammer dine personoplysninger fra:
                <br />
                - Registrerede data stammer fra dine egne oplysninger, som du videregiver til din
                brugerprofil på ll33a.dk.
                <br />
                Opbevaring af dine personoplysninger:
                <br />
                - Vi opbevarer dine personoplysninger i vores medlemssystem, i vores mailsystem og
                på vores dokumentdrev.
                <br />
                - Vi opbevarer dine personoplysninger, så længe du er har en aktiv brugerprofil hos
                os.
                <br />
                Du har ret til at bede om at blive slettet i det omfang, vi kan overholde
                bogføringslovens krav til opbevaring. Du kan altid frabede dig henvendelser.Formål:
                Når du opretter dig som bruger, har vi en række forpligtelser over for dig som
                bruger. Disse forpligtelser indebærer følgende:
            </p>

            <h3>Når du handler hos os, behandles følgende personoplysninger:</h3>

            <p>
                Formålet med databehandlingen er opfyldelse af leverance- og aftaleforpligtelser.
                <br />
                Hvilke typer personoplysninger behandler vi:
                <br />
                - Vi behandler navn, stilling, telefonnummer, mailadresse, samt data afgivet af dig,
                med det formål at gøre brug af ll33a.dk's services.
                <br />
                Retsgrundlaget for vores behandling af dine personoplysninger følger af:
                <br />
                - Retsgrundlaget for databehandlingen er dit samtykke og kontrakt for leverance.
                <br />
                Hvor stammer dine personoplysninger fra:
                <br />
                - Dine oplysninger stammer fra informationer, som du selv har angivet til os.
                <br />
                Opbevaring af dine personoplysninger:
                <br />- Vi opbevarer dine personoplysninger i tidsrummet for kundeforholdet og som
                foreskrevet i bogføringsloven.
            </p>

            <h3>
                Når du abonnerer på GEO tracking, flight-radar tracking, seavessel tracking, samt
                kreditkort transaktioner, behandles følgende personoplysninger:
            </h3>

            <p>
                Formålet med behandlingen af personoplysninger, når du bruger ovenstående services
                er, at kunne øge din dokumentations indsamling.
                <br />
                Hvilke typer personoplysninger behandler vi:
                <br />
                - Vi behandler persondata, som med dit samtykke er indsamlet gennem
                underleverandører, din telefons GPS modtager og/eller din kreditkort udsteder. Vi
                indsamler ikke yderligere data om brugerne. Hertil kan der indgå persondata i
                medlemmernes forespørgsler om deres klientforhold, som vi er dataansvarlige i
                forhold til.
                <br />
                - Vi registrerer oplysningerne med dit samtykke i forbindelse med din oprettelse af
                bruger profil/udvidelse af dine abonnementer.
                <br />
                Retsgrundlaget for vores behandling af dine personoplysninger følger af:
                <br />
                - GEO tracking, flight-radar tracking, seavessel tracking, samt kreditkort
                transaktioner er et servicetilbud. Benyttelse af ordningerne indebærer derfor, at
                brugeren, der ønsker denne abonnementsudvidelse, har afgivet samtykke derved.
                <br />
                Hvor stammer dine personoplysninger fra:
                <br />
                - Persondataene kommer fra vores samarbejdpartnere.
                <br />
                Opbevaring af dine personoplysninger:
                <br />- Vi opbevarer dine personoplysninger som en del af vores leverance af dit
                abonnement, og vi arkiverer dine data, så længe det er relevant for formålet.
            </p>

            <h3>Hvis du ansøger om et job hos os, behandles følgende personoplysninger:</h3>

            <p>
                Formålet med behandlingen af persondata, hvis du ansøger om job hos os, er
                udvælgelse af en kandidat samt en eventuel ansættelsesprocedure.
            </p>

            <p>
                Hvilke typer personoplysninger behandler vi:
                <br />
                Vi behandler de oplysninger, du vælger at oplyse i din ansøgning. Som udgangspunkt
                omfatter sådanne personoplysninger dit navn, adresse og uddannelse, medmindre du
                selv vælger at oplyse andet.
                <br />
                Retsgrundlaget for vores behandling af dine personoplysninger følger af:
                <br />
                Retsgrundlaget for databehandlingen er samtykke, idet du indsender din ansøgning til
                os.
                <br />
                Hvor stammer dine personoplysninger fra:
                <br />
                Vi får oplysningerne fra din ansøgning og dit medsendte CV.
                <br />
                Opbevaring af dine personoplysninger:
                <br />
                Vi opbevarer dine personoplysninger, indtil udvælgelse af en kandidat er sket.
                Herefter sletter vi dine oplysninger, medmindre andet er aftalt med dig.
            </p>

            <h3>Hvad er en cookie?</h3>

            <p>
                En cookie er en lille tekstfil, der lagres i din browser for at kunne genkende din
                computer, tablet eller smart phone ved tilbagevendende besøg. Næsten alle websites
                bruger i dag cookies. En cookie er ikke et program og kan ikke indeholde virus. En
                cookie kan indeholde tekst, tal, en dato eller kan huske, at du er logget ind. Det
                er eksempelvis ikke muligt at logge ind på ll33a.dk, hvis vores login-cookies ikke
                sættes.
            </p>

            <h3>Sådan undgår og sletter du cookies</h3>

            <p>
                Hvis du ikke ønsker at lagre cookies på din computer, har du mulighed for at slette
                dem igen eller blokere for cookies.
            </p>
            <p>
                Sådan sletter eller blokerer du cookies (information fra minecookies.org).
                <br />
                Benytter du en browser, som ikke er på listen fra minecookies.org ovenfor, skal du
                finde vejledningen i den pågældende browsers hjælpe-sektion. Du skal dog være
                opmærksom på, at ll33a.dk - og øvrige websites - ikke vil fungere efter hensigten,
                hvis du helt fravælger cookies. Det vil blandt andet være umuligt at logge ind, da
                vi bruger cookies i forbindelse med vores login-funktionalitet.
            </p>
            <p>
                Så lang tid opbevares cookies:
                <br />
                Cookiens levetid kan variere efter, hvad de enkelte udbydere har sat dem til. Nogle
                cookies forsvinder, så snart du lukker browservinduet, andre kan eksistere i flere
                år. Mange udbydere anvender typisk 12 eller 24 måneders levetid på deres annonce-,
                statistik og indholdsrelaterede cookies. Bemærk, at levetiden forlænges efter hvert
                besøg.
            </p>

            <h4>
                Dette er Ikenson ApS’ persondata- og cookiepolitik. Sidst opdateret 13. januar 2021
            </h4>
        </div>
    </Layout>
)

export default TermsPage
